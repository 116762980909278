import React, { useState } from 'react';
import { Button, CustomInput, TextAreaInput, RadioInput } from '../../../atoms';
import { ErrorForm } from '../../../atoms/Cards';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import {
  cannonStyled,
  useCannonStyletron,
  TWH4,
  TWParagraph3,
} from '../../../theme';
import { useAPIURL, useTableOrMobile, useCoreAPIURL } from '../../../hooks';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import ArrowRight from '../../../assets/arrow-right.svg';
import PhoneIcon from '../../../assets/phone.svg';

import invalidCharsInput from '../../../atoms/js/invalidCharsInput';

export interface IContactProps {
  src?: string;
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}
export interface IPersonalInformation {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  fileUpload: File;
  subscribe: boolean;
  contactBy: string;
  showThanks: () => void;
}

interface Props {
  informationProp: IPersonalInformation;
}

const FlexContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  width: '100%',
  marginRight: '-10px',
});

const PhoneIconStyled = cannonStyled(PhoneIcon, (props) => ({
  stroke: props.$theme.cannonColors.secondarySilver,
}));

const SubmitButtonContainer = cannonStyled('div', {
  marginTop: '59px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const InformationForm = (props: IPersonalInformation) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  const coreAPI_URL = useCoreAPIURL();

  // STATE
  const [information, setInformation] = useState({} as IPersonalInformation);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [loading, setLoading] = useState(false);

  //EFFECT
  // HANDLERS && FUNCTIONS
  function handleChange(value: string, key: string) {
    if (key === 'email') {
      const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
      setEmailInvalid(!valid);
    }
    const tmp = { ...information };
    tmp[key] = value;
    setInformation(tmp);
  }

  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        props.showThanks();
        return r;
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setShowFetchError(true);
        setLoading(false);
      });
    console.log('respose contact info', response);
  }

  async function sendSubmit(value: any) {
    setLoading(true);
    const { fileUpload } = value;
    if (fileUpload?.name) {
      const myRenamedFile = new File(
        [fileUpload],
        new Date().getTime().toString() +
          '-upload-contact-file' +
          '.' +
          fileUpload.name.split('.')[fileUpload.name.split('.').length - 1]
      );
      const formData = new FormData();
      formData.append('file', myRenamedFile);
      const response = await fetch(coreAPI_URL + '/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          // Accept: 'multipart/form-data',
        },
        body: formData,
      })
        .then((r) => r.json())
        .then((r) => {
          const { data = {} as { url: string }, message } = r;
          if (message === 'File saved.') {
            const { url = '' } = data;
            value.fileUpload = url;
            sendInfo(value);
          } else {
            setShowFetchError(true);
          }
          return r;
        })
        .catch((e) => setShowFetchError(true));
      console.log('respose contact fle', response);
    } else {
      delete value.fileUpload;
      sendInfo(value);
    }
  }

  const {
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    comment = '',
    fileUpload,
    subscribe = '',
    contactBy = '',
  } = information;
  return (
    <>
      {showFetchError && <ErrorForm />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!emailInvalid) {
            sendSubmit(information);
          }
        }}
      >
        <FlexContainer>
          <CustomInput
            noMarginLeft
            errorMessage='Field Required'
            error={firstName === '' && submitClicked}
            positive={firstName !== ''}
            label='First Name'
            placeholder='E.g. John'
            value={firstName}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, 'firstName')
            }
            required
            requiredStyle
          />
          <CustomInput
            errorMessage='Field Required'
            error={lastName === '' && submitClicked}
            positive={lastName !== ''}
            label='Last name'
            placeholder='E.g. Doe'
            value={lastName}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, 'lastName')
            }
            required
            requiredStyle
          />
        </FlexContainer>
        <CustomInput
          noMarginLeft
          errorMessage={
            email !== '' && emailInvalid ? 'Email Invalid' : 'Field Required'
          }
          error={(email === '' && submitClicked) || emailInvalid}
          positive={email !== ''}
          label='Email'
          placeholder='Enter an email that you constantly check'
          value={email}
          onChange={(e: any) => handleChange(e.currentTarget.value, 'email')}
          required
          requiredStyle
        />
        <CustomInput
          noMarginLeft
          startEnhancer={() => <PhoneIconStyled />}
          errorMessage={phone === '' ? 'Field required' : 'Follow the format'}
          type='number'
          placeholder='+1-000-000-0000'
          error={
            (phone === '' && submitClicked) ||
            (phone.length < 10 && phone !== '')
          }
          positive={phone !== ''}
          label='Phone'
          value={phone}
          onKeyDown={(e: any) => invalidCharsInput(e, ['e', '-'])}
          onChange={(e: any) => {
            handleChange(e.currentTarget.value, 'phone');
          }}
          required
          requiredStyle
        />
        <TextAreaInput
          noMarginLeft
          rows={4}
          errorMessage='Field Required'
          placeholder='I have a problem with my safe lock'
          error={comment === '' && submitClicked}
          positive={comment !== ''}
          label='How can we assist you?'
          value={comment}
          onChange={(e: any) => handleChange(e.currentTarget.value, 'comment')}
          required
          requiredStyle
        />
        <CustomInput
          noMarginLeft
          type='file'
          label='Upload media or documents to help us better serve you'
          onChange={(e: any) =>
            handleChange(
              e.currentTarget.files?.length > 0
                ? e.currentTarget.files[0]
                : fileUpload,
              'fileUpload'
            )
          }
        />
        <TWH4
          weight={400}
          color={theme.cannonColors.secondarySilver}
          align='left'
          margin={10}
          $style={{
            fontSize: '14px',
            paddingBottom: '20px',
          }}
        >
          Attach videos, images or other documentation here. PDF, JPEG, PNG,
          MP4, MOV, or WMV of max 5MB per load
        </TWH4>
        {/* <RadioInput
          label='How would you like us to respond?'
          radioMarginRight='34px'
          radioElements={[
            { radioValue: 'By email' },
            { radioValue: 'By Phone' },
          ]}
          errorMessage='Please Choose One'
          noMarginLeft
          error={contactBy === '' && submitClicked}
          onChange={(e: any) =>
            handleChange(e.currentTarget.value, 'contactBy')
          }
          value={contactBy}
          required
          requiredStyle
        /> */}
        <Checkbox
          checked={information.subscribe}
          onChange={(e: any) => {
            e.stopPropagation();
            handleChange(e.target.checked, 'subscribe');
          }}
          overrides={{
            Checkmark: {
              style: {
                borderLeftColor: theme.cannonColors.primaryBlue,
                borderRightColor: theme.cannonColors.primaryBlue,
                borderTopColor: theme.cannonColors.primaryBlue,
                borderBottomColor: theme.cannonColors.primaryBlue,
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                borderWidth: '2px',
                fontSize: '2px',
                margin: '25px 0 0 5px',
              },
            },
          }}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          <TWParagraph3
            align='left'
            weight={400}
            color={theme.cannonColors.secondarySilverDark}
            $style={{
              fontSize: isMobile ? '14px' : '16px',
              margin: '23px 0 0 0',
            }}
          >
            I want to subscribe to the Cannon Customer newsletter
          </TWParagraph3>
        </Checkbox>
        <SubmitButtonContainer>
          <Button.RoundedButton
            onClick={() => {
              setSubmitClicked(true);
            }}
            endEnhancer={() => <ArrowRight style={{ stroke: 'white' }} />}
            size='compact'
            type='submit'
            color='primary'
            fullWidth
            isLoading={loading}
          >
            Continue
          </Button.RoundedButton>
        </SubmitButtonContainer>
      </form>
    </>
  );
};
export default InformationForm;
