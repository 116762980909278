import React, { useEffect } from 'react';
import {
  cannonStyled,
  NSH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { Button } from '../../../atoms';

import ThanksMail from '../../../assets/thanks-mail.svg';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';

import scrollTo from 'gatsby-plugin-smoothscroll';

export interface IContactProps {
  src?: string;
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
}

export interface IPersonalInformation {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  fileUpload: File;
  subscribe: string;
  contactBy: string;
}

interface Props {
  informationProp: IPersonalInformation;
}

const MainContainer = cannonStyled('div', {
  height: '60vh',
  paddingTop: '30px',
});

const ButtonContainer = cannonStyled('div', {
  margin: '10px',
});

const ButtonsContainer = cannonStyled('div', {
  marginTop: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InformationForm = () => {
  const [css, theme] = useCannonStyletron();

  useEffect(() => {
    setTimeout(() => {
      scrollTo('#thanksContainer');
    }, 500);
  }, []);

  return (
    <>
      <ThanksMail id='thanksContainer' />
      <NSH4
        style={{ fontSize: '36px', paddingTop: '30px' }}
        color={theme.cannonColors.primaryBlue}
        weight={900}
        align='center'
      >
        Thanks for contacting us!
      </NSH4>
      <TWParagraph3
        style={{ fontSize: '19px', paddingTop: '12px' }}
        align='center'
        color={theme.cannonColors.secondarySilverDark}
        weight={400}
      >
        We will be in touch with you shortly.
      </TWParagraph3>
      <ButtonsContainer>
        <ButtonContainer>
          <Button.RoundedButton
            size='compact'
            color='primary'
            onClick={() => {
              window.location.href = '/faq';
            }}
          >
            Go to FAQ’s
          </Button.RoundedButton>
        </ButtonContainer>
      </ButtonsContainer>
    </>
  );
};

export default InformationForm;
